// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clothes-js": () => import("./../../../src/pages/clothes.js" /* webpackChunkName: "component---src-pages-clothes-js" */),
  "component---src-pages-components-background-section-2-js": () => import("./../../../src/pages/components/backgroundSection2.js" /* webpackChunkName: "component---src-pages-components-background-section-2-js" */),
  "component---src-pages-components-background-section-js": () => import("./../../../src/pages/components/backgroundSection.js" /* webpackChunkName: "component---src-pages-components-background-section-js" */),
  "component---src-pages-components-clothes-gallery-js": () => import("./../../../src/pages/components/clothesGallery.js" /* webpackChunkName: "component---src-pages-components-clothes-gallery-js" */),
  "component---src-pages-components-creative-header-js": () => import("./../../../src/pages/components/creativeHeader.js" /* webpackChunkName: "component---src-pages-components-creative-header-js" */),
  "component---src-pages-components-fashionshow-gallery-js": () => import("./../../../src/pages/components/fashionshowGallery.js" /* webpackChunkName: "component---src-pages-components-fashionshow-gallery-js" */),
  "component---src-pages-components-footer-js": () => import("./../../../src/pages/components/footer.js" /* webpackChunkName: "component---src-pages-components-footer-js" */),
  "component---src-pages-components-header-js": () => import("./../../../src/pages/components/header.js" /* webpackChunkName: "component---src-pages-components-header-js" */),
  "component---src-pages-creative-js": () => import("./../../../src/pages/creative.js" /* webpackChunkName: "component---src-pages-creative-js" */),
  "component---src-pages-fashion-show-js": () => import("./../../../src/pages/fashion-show.js" /* webpackChunkName: "component---src-pages-fashion-show-js" */),
  "component---src-pages-hire-js": () => import("./../../../src/pages/hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portrait-js": () => import("./../../../src/pages/portrait.js" /* webpackChunkName: "component---src-pages-portrait-js" */),
  "component---src-templates-project-template-js": () => import("./../../../src/templates/projectTemplate.js" /* webpackChunkName: "component---src-templates-project-template-js" */)
}

